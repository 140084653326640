import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import * as _ from 'lodash';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  lastAccessed = '';
  private readonly componentName = 'LandingPage';

  constructor(private userPreferenceService: UserPreferenceApiService, private loggingService: LoggingApiService) {}

  ngOnInit() {
    //     this.loggingService.setContext('Context1', 'Value1');
    //     this.loggingService.setContext('Context2', 'Value2');
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = this.componentName;
    this.userPreferenceService
      .getUserPreference(getPreferencesQuery, { loadingOverlayEnabled: false })
      .subscribe(async (getPreferencesResponse) => {
        const json = _.get(getPreferencesResponse, 'preferences', '');
        const preferences = JSON.parse(json && json.length > 0 ? json : '{}');
        this.lastAccessed = _.get(preferences, 'lastAccessed', '');

        await this.updateUserPreferences()
          .then(() => {
            console.log('User preferences updated!');
          })
          .catch((error) => {
            console.log('ERROR');
          });
      });
  }

  ngOnDestroy() {
    //     this.loggingService.clearContext('Context1');
    //     this.loggingService.clearContext('Context2');
  }

  private async updateUserPreferences(): Promise<any> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = this.componentName;
    upsertPreferencesRequest.preferences = JSON.stringify({ lastAccessed: new Date() });
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceService
      .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery)
      .toPromise();
  }
}
