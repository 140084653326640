import { Injectable } from '@angular/core';
import { SearchFormComponent } from '../../search-page/search-form/search-form.component';

@Injectable({
  providedIn: 'root',
})
export class SearchFormService {
  private formComponent: SearchFormComponent;
  constructor() {}
  search() {
    if (this.formComponent) {
      this.formComponent.search();
    }
  }
  setFormComponent(component: any) {
    this.formComponent = component;
  }
  clearForm() {
    if (this.formComponent) {
      this.formComponent.clearForm(new MouseEvent('click'));
    }
  }
}
