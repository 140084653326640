import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import 'jimp';
import { DocumentsService } from '../shared/services/documents.service';
import { DmsRetrieveDocumentRqst } from '../shared/services/sdk-dms-2.0/lib/classes/dms-retrieve-document-rqst';
const { Jimp } = window as any;

@Component({
  selector: 'app-modal',
  templateUrl: './blob-visor.component.html',
  styleUrls: ['./blob-visor.component.scss'],
})
export class BlobVisorComponent implements OnInit {
  constructor(
    private documentService: DocumentsService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { blobUrl: string; contentType: string; blb: Blob; displayName: string; cdt }
  ) {
    this.fileType = this.contentTypeToFiletype(data.contentType);
    this.safeUrl = this.sanitizeUrl(data.blobUrl);
    this.displayName = data.displayName;
    this.cdt = data.cdt;

    this.initializeSources(data);
  }

  fileType: string;
  safeUrl: SafeResourceUrl;
  displayName: string;
  cdt: { docClass: string; corpCode: string; timestamp: string };
  onDownload() {
    this.documentService.onDownload({ ...this.cdt } as DmsRetrieveDocumentRqst);
  }

  async initializeSources(data) {
    switch (data.contentType) {
      case 'application/xml':
        this.safeUrl = await this.getBlobAsText(data.blb);
        break;
      case Jimp.MIME_TIFF:
        this.safeUrl = await this.convertTiffToPng(data.blobUrl);
        break;
    }
  }

  ngOnInit(): void {}

  sanitizeUrl(blobUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  async getBlobAsText(blb: Blob): Promise<SafeResourceUrl> {
    return new Promise<SafeResourceUrl>(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const blobContent = reader.result as ArrayBuffer;
        const txtUrl = window.URL.createObjectURL(new Blob([blobContent]));
        // Listen for the window unload event to revoke the URL
        window.addEventListener('unload', () => {
          URL.revokeObjectURL(txtUrl);
        });
        resolve(this.sanitizeUrl(txtUrl));
      };
      reader.onerror = reject;
      reader.readAsText(blb);
    });
  }

  async convertTiffToPng(tiffUrl: string): Promise<SafeResourceUrl> {
    const image = await Jimp.read(tiffUrl);
    const pngData = await image.getBufferAsync(Jimp.MIME_PNG);
    const pngUrl = window.URL.createObjectURL(new Blob([pngData]));
    // Listen for the window unload event to revoke the URL
    window.addEventListener('unload', () => {
      URL.revokeObjectURL(pngUrl);
    });
    return this.sanitizeUrl(pngUrl);
  }

  contentTypeToFiletype(mimeType: string) {
    switch (mimeType) {
      case 'application/pdf':
        return 'pdf';
      case 'application/xml':
        return 'xml';
      case Jimp.MIME_JPEG: // "image/jpeg"
        return 'image';
      case Jimp.MIME_PNG: // "image/png"
        return 'image';
      case Jimp.MIME_TIFF: // "image/tiff"
        return 'tiff';
      case 'text/plain':
        return 'text';
      default:
        return 'binary';
    }
  }
}
