<div class="blob-visor__header">
  <div>display name: {{ displayName }}</div>
  <!-- <div>blobUrl: {{ data.blobUrl }}</div>
  <div>safeUrl: {{ safeUrl }}</div>
  <div>contentType: {{ data.contentType }}</div>
  <div>fileType: {{ fileType }}</div> -->
  <button
    mat-icon-button
    class="button-action"
    (click)="onDownload()"
    matTooltip="Download archive"
    aria-tooltip="button that downloads archive"
  >
    <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
  </button>
</div>
<ng-container [ngSwitch]="fileType">
  <ng-container *ngSwitchCase="'image'">
    <img [src]="safeUrl" />
  </ng-container>
  <ng-container *ngSwitchCase="'tiff'">
    <!-- <object [data]="safeUrl" type="image/tiff" width="85vw" height="90vh"></object> -->
    <img [src]="safeUrl" />
  </ng-container>
  <ng-container *ngSwitchCase="'pdf'">
    <iframe [src]="safeUrl" type="application/pdf" style="width: 85vw; height: 90vh"></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'text'">
    <iframe [src]="safeUrl" type="text/plain" style="width: 80vw; height: 90vh;"></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'xml'">
    <!-- <iframe [src]="safeUrl" type="text/xml" style="width: 80vw; height: 90vh;"></iframe> -->
    <iframe [src]="safeUrl" type="text/plain" style="width: 80vw; height: 90vh;"></iframe>
  </ng-container>
</ng-container>
