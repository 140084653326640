<xpo-board
  id="myUniqueIdBuildsCoffins"
  [dataSource]="dataSource"
  [viewTemplates]="viewTemplates"
  [views]="views$.getValue()"
  [boardOptions]="boardOptions"
  (boardReady)="onBoardReady($event)"
>
  <!--
      (REMOVE COMMENT):
      If your back end is using xrt or enabled to do pagination:
        rowModelType="infinite"
      If you have a checkbox row and want the user to only be able to select one row
        rowSelection="single"
    -->
  <xpo-empty-state *ngIf="isSearchCriteriaDefined(); else noSearchCriteriaDefinedEmptyState">
    <xpo-empty-state-icon>
      <xpo-icon iconName="info" xpoIconLarge></xpo-icon>
    </xpo-empty-state-icon>
    <xpo-empty-state-header>No results found</xpo-empty-state-header>
    <xpo-empty-state-description>Try modifying search criteria</xpo-empty-state-description>
    <xpo-empty-state-actions>
      <xpo-button-group>
        <button mat-stroked-button xpoSmallButton (click)="onResetSearchCriteria()">
          <mat-icon>filter_list_off</mat-icon> Reset search criteria
        </button>
        <button mat-flat-button xpoSmallButton (click)="onClickRetry()"><mat-icon>refresh</mat-icon>RETRY</button>
      </xpo-button-group>
    </xpo-empty-state-actions>
  </xpo-empty-state>
  <ng-template #noSearchCriteriaDefinedEmptyState>
    <xpo-empty-state>
      <xpo-empty-state-icon>
        <mat-icon xpoIconLarge>manage_search</mat-icon>
      </xpo-empty-state-icon>
      <xpo-empty-state-header>Please enter search criteria</xpo-empty-state-header>
      <xpo-empty-state-description
        >Mandatory fields include: CorpCode, search string and archive date range</xpo-empty-state-description
      >
    </xpo-empty-state>
  </ng-template>

  <div *ngIf="appliedFiltersVisible">
    <xpo-applied-filters></xpo-applied-filters>
  </div>
  <xpo-ag-grid-board
    rowModelType="client-side"
    [enableSorting]="true"
    rowSelection="multiple"
    [enableMovableColumns]="true"
    (gridReady)="onGridReady($event)"
    (gridBoardReady)="onGridBoardReady($event)"
    [customGridOptions]="customGridOptions"
    [paginationPageSizes]="[25, 50, 75]"
    [paginationPageSizeStart]="50"
    [pagination]="dmsPagination"
  >
  </xpo-ag-grid-board>
  <xpo-board-pagination #dmsPagination></xpo-board-pagination>
</xpo-board>
