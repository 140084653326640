import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import Jimp from 'jimp';
import { BlobVisorComponent } from '../../blob-visor/blob-visor.component';
import { DocumentsService } from '../../shared/services/documents.service';
import { ModalService } from '../../shared/services/modal.service';
import { DmsRetrieveDocumentRqst } from '../../shared/services/sdk-dms-2.0/lib/classes/dms-retrieve-document-rqst';
import { SearchPageComponent } from '../search-page.component';

interface ViewActionsRendererParams extends ICellRendererParams {
  gridComponent: SearchPageComponent;
}

@Component({
  selector: 'app-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrls: ['./actions-renderer.component.scss'],
})
export class ActionsRendererComponent implements ICellRendererAngularComp {
  params: ViewActionsRendererParams;

  constructor(
    @Inject(DocumentsService) private documentService: DocumentsService,
    private modalService: ModalService
  ) {}

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  agInit(params: ViewActionsRendererParams): void {
    this.params = params;
  }
  onDownload() {
    const nodeId = this.params.node.id;
    this.documentService.onDownload({ ...this.params.node.data.cdt } as DmsRetrieveDocumentRqst);
  }
  mimeTypeToExtension(mimeType: string) {
    switch (mimeType) {
      case 'application/pdf':
        return '.pdf';
      case 'application/xml':
        return '.xml';
      case Jimp.MIME_JPEG:
        return '.jpeg';
      case Jimp.MIME_PNG:
        return '.png';
      case Jimp.MIME_TIFF:
        return '.tiff';
      case 'text/plain':
        return '.txt';
      default:
        return '.bin';
    }
  }
  onViewFullScreen() {
    const nodeId = this.params.node.id;
    const rqst = {
      corpCode: this.params.node.data.cdt.corpCode,
      docClass: this.params.node.data.cdt.docClass,
      timestamp: this.params.node.data.cdt.timestamp,
    } as DmsRetrieveDocumentRqst;
    const metadata = this.params.node.data.metadata;
    const displayName = metadata.find((m) => m.tag === 'displayname');
    this.documentService.loadDocument(rqst).subscribe((data) => {
      this.modalService.openModal(BlobVisorComponent, {
        ...data,
        displayName: displayName.value,
        cdt: this.params.data.cdt,
      });
    });
  }
  onViewInNewTab() {
    // console.debug('on view in new tab params', this.params);
    this.params.gridComponent.openViewerTab(this.params.node.data.timestampISO);
    // this.params.gridComponent.viewTemplates[1].createView(viewerViewConfig);
  }

  onViewInTray() {}
}
