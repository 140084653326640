import { Metadata } from './dms-archive-document-rqst';

export class DmsDocumentSearchResp {
  cdt: {
    corpCode: string;
    docClass: string;
    timestamp: string;
  };
  timestampISO: string;
  metadata: Metadata[];
}
