import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  AuthUserProfileComponent,
  XpoAuthUserProfileActionsDirective,
  XpoAuthUserProfileLinksDirective,
} from '@xpo-ltl/ngx-auth-ui';
import {
  XpoLtlAppSwitcherService,
  XpoLtlFeedbackService,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesService,
} from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoFeedback } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoShellModule, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { delay, retryWhen, skipWhile, take } from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader/dynamic-script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  constructor(
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private dialog: MatDialog,
    //     private appSwitcherService: XpoLtlAppSwitcherService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private authService: XpoLtlAuthenticationService,
    private loggedInUserService: XpoLtlLoggedInUserService
  ) {
    this.routes = [
      {
        label: 'Search',
        path: `/${AppRoutes.SEARCH_PAGE}`,
      },
      //       {
      //         label: 'Data Entry Example',
      //         path: `/${AppRoutes.DATA_ENTRY_PAGE}`,
      //       },
    ];

    /** Shell setup */
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    /** Initialize SIC switcher by getting logged in user's sic */
    this.loggedInUserService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });

    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      // Extra config or API calls here if needed
    });
  }
  title: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  defaultSic = 'UPO';
  accountPopoverConfig: XpoAccountPopoverConfig;
  notifications = [{ title: 'Important notification 1', url: 'https://tcts3-app.ltl.xpo.com/appjs/ngx-ltl-core-docs' }];

  private readonly currentUserSubject = new BehaviorSubject<User>(undefined);
  readonly currentUser$ = this.currentUserSubject.asObservable();

  readonly AccountUrls = AccountUrls;

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  handleSearchClick(): void {}

  handleSicChanged(newSic: string): void {
    /** TODO: how does your app respond to SIC changes? Do you need to refresh something... */
    console.log('New SIC', newSic);
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private handleLoggedInUser(): void {
    this.currentUserSubject.next(undefined);
    // this.loggedInUserService
    //   .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
    //   .pipe(retryWhen((errors) => errors.pipe(delay(10000), take(5))))
    //   .subscribe(
    //     (user: User) => {
    //       if (user) {
    //         this.currentUserSubject.next(user);
    //         console.log(`You're logged in as user `, user);
    //         this.initializeDynatrace(user);
    //         this.populateAccountPopover(user);
    //         this.populateAppSwitcher();
    //       }
    //     },
    //     (error) => {
    //       console.log('ERROR', error);
    //     }
    //   );
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: '', // AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    // this.loginService.clear();
  }

  private populateAppSwitcher(): void {
    console.log('populateAppSwitcher() implementation disabled...');
    //     this.apps$ = this.appSwitcherService.getAppList();
  }

  private initializeDynatrace(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${_toLower(this.configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${_toLower(region)}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        _invoke(window['dtrum'], 'identifyUser', !_isEmpty(u.emailAddress) ? u.emailAddress : u.userId);
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            setIdentity(user);
          });
      }
    });
  }
}
