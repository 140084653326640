import { Component, OnDestroy, OnInit } from '@angular/core';
import { XpoAgGridComponent } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

/////////////////////////
// TEST SET
import { ChangeDetectionStrategy } from '@angular/core';
import {
  XpoBoardReadyEvent,
  XpoBoardView,
  XpoBoardViewTemplate,
  XpoBoardViewTemplateConfig,
  XpoDateRangeFilter,
  XpoMultiSelectAutocompleteFilter,
  XpoQuickSearchFilter,
} from '@xpo-ltl/ngx-ltl-board';

import {
  ConditionalFilterCombiner,
  Operators,
  XpoAccordionGroupCellRendererComponent,
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardStateViewer,
  XpoBoardViewConfig,
  XpoBooleanFilterComponent,
  xpoDateFormatter,
  XpoDateOnlyFilterComponent,
  XpoDateTimeFilterComponent,
  xpoDateTimeFormatter,
  XpoDynamicEnumFilterComponent,
  XpoEnumFilterComponent,
  XpoLocalStorageBoardViewDataStore,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
  XpoTimeFilterComponent,
  xpoTimeFormatter,
  XpoTimeRangeFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import {
  XpoAgGridBoardReadyEvent,
  XpoAgGridBoardView,
  XpoAgGridBoardViewConfig,
  XpoAgGridBoardViewTemplate,
  XpoAgGridBoardViewTemplateConfig,
} from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ltl-grid';
import { IFilterAngularComp } from 'ag-grid-angular';
import { GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
// END TEST SET
/////////////////////////

import { DatePipe } from '@angular/common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import * as _ from 'lodash';
import { DocumentsService } from '../shared/services/documents.service';
import { ModalService } from '../shared/services/modal.service';
import { SearchFormService } from '../shared/services/search-form.service';
import { ActionsRendererComponent } from './actions-renderer/actions-renderer.component';
import { ColumnActionsHeader } from './column-actions-header/column-actions-header.component';
import { SimpleAgGridDocumentSearchDataSourceService } from './simple-ag-grid-document-search-data-source';
// import { SimpleAgGridProductionSampleDataSourceService } from './simple-ag-grid-sample-data-source.service';
import { TagRendererComponent } from './tag-renderer/tag-renderer.component';
@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPageComponent implements OnInit, OnDestroy {
  boardApi: import('@xpo-ltl/ngx-ltl-board').XpoBoardApi;
  agGridBoardApi: import('@xpo-ltl/ngx-ltl-board-grid').XpoAgGridBoardApi;
  gridApi: GridApi;
  filterService: import('@xpo-ltl/ngx-ltl-board').XpoFiltersService;
  agGirdColumnApi: ColumnApi;
  agGridApi: GridApi;
  girdColumnApi: ColumnApi;
  constructor(
    public dataSource: SimpleAgGridDocumentSearchDataSourceService,
    private documentService: DocumentsService,
    private searchFormService: SearchFormService,
    private userPreferenceService: UserPreferenceApiService,
    private loggingService: LoggingApiService,
    private modalService: ModalService
  ) {
    this.dataSource.pageSize = 1000;
    this.viewDataStore = new XpoLocalStorageBoardViewDataStore('client-side-sample-views', this.getInitialViews());
  }
  lastAccessed = '';
  private readonly componentName = 'SearchPage';
  appliedFiltersVisible: boolean = false;

  /////////////////////////
  // MORE TEST STUFF
  viewTemplates: XpoAgGridBoardViewTemplate[];
  readonly viewDataStore: XpoLocalStorageBoardViewDataStore;
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  views$ = new BehaviorSubject<XpoAgGridBoardView[]>([]);
  boardOptions: XpoBoardOptions = {
    // suppressViewSwitcher: true,
    suppressGridDensity: true,
    suppressGridSettingsPopover: true,
    enableFilterReset: true,
  };

  customGridOptions: GridOptions = {
    pagination: true,
    defaultColDef: {
      menuTabs: [],
    },
    suppressPaginationPanel: false,
  };

  viewerTabTemplateConfig: XpoAgGridBoardViewTemplateConfig = {
    id: 'viewer-template',
    name: 'detailed-viewer-view',
    keyField: 'timestampISO',
    allowAdditional: false,
    availableFilters: [],
    availableColumns: [
      { colId: 'viewer-col', headerName: 'Viewer', field: 'timestampISO', filter: 'false', autoHeight: true },
    ],
  };

  sampleViewerViewConfig: XpoAgGridBoardViewConfig = {
    // id: 'timestampISO - 2023-03-22T17:27:26.875626Z',
    name: 'timestampISO - 2023-03-22T17:27:26.875626Z',
    templateId: 'viewer-template',
    criteria: { timestampISO: '2023-03-22T17:27:26.875626Z' },
    visibleFilters: [],

    visibleColumns: [
      // "viewer-col"
      {
        // colId: 'viewer-col',
        headerName: 'Viewer',
        width: 100,
        // pinned?: string,
        // children?: any[],
        // rowGroup?: boolean,
        // groupId?: string,
        // field: 'timestampISO',
        // hide: false,
      },
    ],
  };

  onBoardReady(event: XpoBoardReadyEvent) {
    this.boardApi = event.boardApi;
    this.filterService = event.filterService;
  }

  onGridBoardReady(event: XpoAgGridBoardReadyEvent) {
    this.agGridBoardApi = event.agGridBoardApi;
    this.agGirdColumnApi = event.agGridColumnApi;
    this.agGridApi = event.agGridApi;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.girdColumnApi = event.columnApi;
  }

  onClickRetry() {
    this.searchFormService.search();
    this.dataSource.refresh();
  }

  onResetSearchCriteria() {
    this.searchFormService.clearForm();
    this.documentService.clearSearchCriteria();
    this.dataSource.refresh();
  }

  isSearchCriteriaDefined() {
    return this.documentService.isSearchCriteriaDefined();
  }
  // constructor(public dataSource: MockAgGridDataSource, private userPreferenceService: UserPreferenceApiService,
  //             private loggingService: LoggingApiService) {}

  ngOnInit(): void {
    this.viewTemplates = this.getViewTemplates();
    this.views$.next(this.getViews());

    this.dataSource.connect(this).subscribe((state) => { });
  }

  /////////////////////////
  // TEST STUFF
  ngOnDestroy() { }

  // private async updateUserPreferences(): Promise<any> {
  //   const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
  //   upsertPreferencesRequest.uiComponentName = this.componentName;
  //   upsertPreferencesRequest.preferences = JSON.stringify({ lastAccessed: new Date() });
  //   const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
  //   return this.userPreferenceService
  //     .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery)
  //     .toPromise();
  // }

  private getInitialViews(): XpoBoardViewConfig[] {
    return [
      {
        id: 'all-search-results-view',
        templateId: 'searchResultsTemplateID',
        name: 'All search results',
        closeable: false,
        systemDefined: true,
        visible: true,
      },
      {
        id: 'detailed-viewer-view-d',
        templateId: 'viewer-template',
        name: 'Detailed viewer',
        closeable: true,
        systemDefined: true,
        visible: true,
      },
    ];
  }

  private getViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      // (REMOVE COMMENT):
      // View Templates contain filter and column configurations, if your views have different columns/filters,
      // Create a view template for each of them
      new XpoAgGridBoardViewTemplate({
        id: 'searchResultsTemplateID',
        name: 'Search Results',
        // (REMOVE COMMENT):
        // If you want the user to have the ability
        // to add more views based on this view template,
        // set allowAdditional to true. This is usually true
        // when you have a viewDataStore connected to the board to save
        // the users configuration on a view, please see other sample for that implementation
        allowAdditional: false,
        availableFilters: [
          // (REMOVE COMMENT):
          // Place all of your filters here, see all available filters in the other samples
          // new XpoQuickSearchFilter('q'),
          // new XpoTextInputFilter('cdt.corpCode', 'Corp Code' ),
          // new XpoTextInputFilter('cdt.docClass', 'Doc class' ),
          new XpoMultiSelectAutocompleteFilter(
            'metadata',
            'contains tags',
            (input) => this.documentService.getTagSelectOps(input)
            //  (input: string) => of([
            //     {title: 'test', code: 'TEST', checked: true},
            //     {title: 'my_index', code: 'MY_INDEX', checked: false },
            //     {title: 'dmsrst', code: 'DMSRS', checked: true},
            //   ]).pipe(
            //     map((ops => ops.filter(o => o.title.includes(input)) )
          ),
          // new XpoDateRangeFilter('timestampISO', 'Archive time'),
          // new XpoQuickSearchFilter('metadata' ),
          // new XpoTextInputFilter('metadata', 'Search tag value'),
          // new XpoConditionalFilter(),
          // new XpoFilterChip()
          // new XpoFilterChips()),
        ],
        availableColumns: [
          // (REMOVE COMMENT): Place all of your columns here
          // These column definitions gets passed to ag-grid, find more docs on this API there (ColDef)

          // Uncomment this if you need row selection
          { checkboxSelection: true, width: 50, sortable: false, filter: false },
          // { headerName: 'timestamp', field: 'cdt.timestamp', flex: 0.7 },
          { headerName: 'Corp Code', field: 'cdt.corpCode', flex: 0.2, filter: false },
          { headerName: 'Doc Class', field: 'cdt.docClass', flex: 0.2, filter: false },
          {
            headerName: 'Archive Date',
            field: 'timestampISO',
            cellRenderer: (params) => {
              const datePipe = new DatePipe('en-US');
              return datePipe.transform(params.value, 'MMM d, y h:mm a');
            },
            flex: 0.4,
            filter: 'false',
            wrapText: true,
            autoHeight: true,
          },
          // { headerName: 'Archive Date', field: 'timestampISO', flex: 0.5, lockVisible: true,
          //   filter: 'agDateColumnFilter' },
          {
            headerName: 'View',
            field: 'cdt',
            headerComponentFramework: ColumnActionsHeader,
            cellRendererFramework: ActionsRendererComponent,
            cellRendererParams: { gridComponent: this },
            sortable: false,
            filter: false,
            cellClass: 'center-content',
            width: 90,
          },
          {
            headerName: 'Tags',
            field: 'metadata',
            flex: 1,
            cellRendererFramework: TagRendererComponent,
            menuTabs: ['filterMenuTab'],
            sortable: false,
            // filter: new XpoSelectFilter({field: 'metadata', options: {}, }),
            // filter: 'xpoSelectFilter',
            // filterParams: {

            // },
            floatingFilter: true,
            filter: 'agTextColumnFilter',
            // filter: false,
            // filter: 'xpoStringFilterComponent',
            // filterParams: {
            //   operators: [Operators.EndsWith]
            // },
            // filter: 'XpoMultiSelectAutocompleteFilter',
            filterValueGetter: (m) => {
              const values = JSON.stringify(m.node.data.metadata.map((o) => o.value));
              return values;
            },
            // getQuickFilterText:
            wrapText: true,
          },
          // {
          //   headerName: 'Position',
          //   children: [
          //     { headerName: 'Latitude', field: 'latitude', width: 140 },
          //     { headerName: 'Longitude', field: 'longitude', width: 120 },
          //   ],
          // },
        ],
        keyField: 'timestampISO',
        // (REMOVE COMMENT):
        // this is used to programmatically do actions on rows in the grid, like selecting or focusing rows,
        // If you are not doing this, you can remove this line, if you are, ensure that this key is the unique
        // identifier for the rows in your grid
        // keyField: 'orderId',
        // (REMOVE COMMENT):
        // If you need to echo something to your fetchData call
        // (like the api function to call if they differ based on viewTemplate) you can pass that information here.
        // context: {},
      }),

      // new XpoBoardViewTemplate({ ...this.cnf })
      new XpoAgGridBoardViewTemplate(this.viewerTabTemplateConfig),
    ];
  }

  openViewerTab(timestampISO): void {
    const nextViews: XpoAgGridBoardView[] = this.views$.getValue().concat([
      this.viewTemplates[1].createView({
        id: `${timestampISO}`,
        name: `Viewer -${timestampISO}`,
        criteria: { timestampISO: `${timestampISO}` },
        isActive: true,
      }),
    ]);
    this.views$.next(nextViews);
  }

  private getViews(): XpoAgGridBoardView[] {
    return [
      // (REMOVE COMMENT):
      // views are saved filter/column/criteria configurations,
      // if your view has a preset criteria, you can put that info here
      // The view config object contains more params, but they
      // are usually used when your board has a viewDataStore attached,
      // Please see other sample for more info on that
      this.viewTemplates[0].createView({
        id: 'searchResultsView',
        name: 'Search Results',
      }),
      // this.viewTemplates[1].createView(this.sampleViewerViewConfig),
    ];
  }
}
