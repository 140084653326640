import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XpoBoardModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridModule } from '@xpo/ngx-core-ag-grid';
import { AgGridModule } from 'ag-grid-angular';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import {
  XpoLtlReleaseNotesComponent,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherComponent,
  XpoLtlRoleSwitcherModule,
  XpoLtlSicSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDateRangePickerModule } from '@xpo-ltl/ngx-ltl-core/date-range-picker';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { AppMetadataApiService } from '@xpo-ltl/sdk-appmetadata';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlobVisorComponent } from './blob-visor/blob-visor.component';
import { DialogModule } from './dialogs/dialog.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { SearchPageModule } from './search-page/search-page.module';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent, BlobVisorComponent],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    LocationApiService,
    NotificationService,
  ],
  entryComponents: [XpoLtlReleaseNotesComponent, XpoLtlRoleSwitcherComponent],
  bootstrap: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    AgGridModule.withComponents([]),
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoDateRangePickerModule,
    XpoIconModule,
    XpoLtlReleaseNotesModule,
    XpoLtlSicSwitcherModule,
    XpoLtlRoleSwitcherModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    MatIconModule,
    MatMenuModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    LandingPageModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    //     XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.DMS_VIEW }),
    XpoLtlAuthUiModule,
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    XpoLtlSicSwitcherModule,
    XpoAgGridModule,
    XpoBoardModule,
    SearchPageModule,
  ],
})
export class AppModule {}
