<xpo-shell
  class="dms-app-shell"
  menuClosedOnDefault="true"
  [routes]="routes"
  [versionNumber]="build"
  [drawerClosedByDefault]="true"
>
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>

  <xpo-header-items>
    <app-search-form></app-search-form>
  </xpo-header-items>
  <xpo-header-utilities-actions>
    <xpo-notification-popover [notifications]="notifications"></xpo-notification-popover>
    <xpo-auth-user-profile>
      <xpo-auth-user-profile-links>
        <a [href]="AccountUrls.myAccount" target="_blank">My Account</a>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>
  <xpo-shell-sidebar-actions class="app-Container-sidebar-actions">
    <div *ngIf="feedbackService.feedbackConfig$ | async">
      <a (click)="handleFeedbackClick()">Feedback</a>
    </div>
    <div>
      <a (click)="handleReleaseNotesClick()">Release Notes</a>
    </div>
  </xpo-shell-sidebar-actions>
  <router-outlet></router-outlet>
</xpo-shell>
<app-notification></app-notification>
