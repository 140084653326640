import { Injectable, Type } from '@angular/core';
import { XrtInMemoryService } from '@xpo/ngx-xrt';
import { Observable } from 'rxjs';
import { DocumentsService } from './documents.service';
import { DmsDocumentSearchResp } from './sdk-dms-2.0/lib/classes/dms-document-search-resp';

@Injectable({
  providedIn: 'root',
})
export class DocumentsXrtService extends XrtInMemoryService {
  constructor(private documentService: DocumentsService) {
    super(
      new Map<Type<any>, () => Observable<any[]>>([
        [DmsDocumentSearchResp, documentService.getDocuments.bind(documentService)],
      ])
    );
  }
}
