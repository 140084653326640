import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlobVisorComponent } from '../../blob-visor/blob-visor.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  openModal(modalComponent, modalData?) {
    const dialogRef = this.dialog.open(BlobVisorComponent, { data: modalData });

    dialogRef.afterClosed().subscribe((res) => {
      // console.debug('dialog result', res);
    });
  }
}
