import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'app-column-actions-header',
  templateUrl: './column-actions-header.component.html',
  styleUrls: ['./column-actions-header.component.scss'],
})
export class ColumnActionsHeader implements OnInit, IHeaderAngularComp {
  constructor() {}
  refresh(params: IHeaderParams): boolean {
    return false;
  }
  agInit(params: IHeaderParams): void {}
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  ngOnInit(): void {}
}
