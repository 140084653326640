<xpo-button-group class="actions_cell_cmpnt">
  <button
    mat-icon-button
    class="button-action"
    (click)="onViewFullScreen()"
    matTooltip="Open archive"
    aria-tooltip="button that open archive in full"
  >
    <mat-icon fontSet="material-symbols-outlined">open_in_full</mat-icon>
  </button>
  <!-- <button mat-icon-button class="button-action" (click)="onViewInNewTab()">
    <mat-icon fontSet="material-symbols-outlined">open_in_new</mat-icon>
  </button>
  <button mat-icon-button class="button-action" (click)="onViewInTray()">
    <mat-icon fontSet="material-symbols-outlined">right_panel_open</mat-icon>
  </button> -->
  <button
    mat-icon-button
    class="button-action"
    (click)="onDownload()"
    matTooltip="Download archive"
    aria-tooltip="button that downloads archive"
  >
    <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
  </button>
</xpo-button-group>
