import { Component, OnInit } from '@angular/core';
import { MatDrawerContainer } from '@angular/material/sidenav';

@Component({
  selector: 'app-side-tray-visor',
  templateUrl: './side-tray-visor.component.html',
  styleUrls: ['./side-tray-visor.component.scss'],
})
export class SideTrayVisorComponent implements OnInit {
  selectedArchive: string;

  constructor() {}

  open(selectedArchive, drawerContainer: MatDrawerContainer) {
    drawerContainer.open();
  }

  ngOnInit(): void {}
}
