import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DmsArchiveDocumentRqst } from './sdk-dms-2.0/lib/classes/dms-archive-document-rqst';
import { DmsDocumentSearchResp } from './sdk-dms-2.0/lib/classes/dms-document-search-resp';
import { DmsDocumentSearchRqst } from './sdk-dms-2.0/lib/classes/dms-document-search-rqst';
import { DmsRetrieveDocumentRqst } from './sdk-dms-2.0/lib/classes/dms-retrieve-document-rqst';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService {
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  retrieveFile(params: DmsRetrieveDocumentRqst): Observable<HttpResponse<Object>> {
    const url = `${this.apiUrl}/${params.corpCode}/${params.docClass}/${params.timestamp}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        // 'Content-Disposition': 'filename='
      }),
      responseType: 'json',
      observe: 'response',
    });
  }

  retrieveTxt(params: DmsRetrieveDocumentRqst) {
    const options: { params: HttpHeaders; responseType?: 'text'; observe: 'body' } = {
      params: new HttpHeaders({
        // 'Content-Type': 'text/plain',
        // 'Content-Type': 'application/octet-stream',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        // 'Access-Control-Allow-Headers': 'Content-Type'
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }),
      responseType: 'text',
      observe: 'body',
    };
    const opsAny = options as any;

    const url = `${this.apiUrl}/${params.corpCode}/${params.docClass}/${params.timestamp}`;
    this.http
      .get<string>(url, opsAny)
      // .pipe(map( response => response as string ))
      .subscribe((data) => {});
  }

  createDocument(params: DmsArchiveDocumentRqst, inputFile: File): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        // the request was rejected because no multipart boundary was found
        // 'Content-Disposition': 'form-data',
        'Content-Type': 'multipart/form-data boundary="loco"',
      }),
    };

    const sampleArchiveRqst = {
      docNumber: '418161811',
      indices: [
        {
          tag: '',
          value: '418161811',
        },
        {
          tag: '',
          value: 'LFW',
        },
        {
          tag: '',
          value: 'WH6N31C3PI',
        },
        {
          tag: 'PRO',
          value: '418161811',
        },
        {
          tag: 'originsic',
          value: 'LFW',
        },
        {
          tag: 'workflowid',
          value: 'WH6N31C3PI',
        },
        {
          tag: 'captureloc',
          value: 'LFW',
        },
      ],
    };

    const body = new FormData();

    // Set the field name for the "archiveRequest" field
    // body.append('Content-Disposition', "form-data; name='archiveRequest'");
    // const archiveRqstHeaders = new Headers();
    // archiveRqstHeaders.append('Content-Disposition', 'form-data; name="archiveRequest"');
    body.append('archiveRequest', JSON.stringify(sampleArchiveRqst));

    // Set the field name and file name for the "inputFile" field
    // body.append('Content-Disposition', "form-data; name='inputFile'; filename='file.txt'");
    // const inputFileHeaders = new Headers();
    // inputFileHeaders.append('Content-Disposition', 'form-data; name="inputFile"; filename=·file.txt"');
    body.append('inputFile', inputFile);

    this.http.post(`${this.apiUrl}/CTS/DR`, body, httpOptions).subscribe((res) => {
      // console.debug(res);
    });

    return of({});
  }

  getDocuments(searchParams: DmsDocumentSearchRqst): Observable<DmsDocumentSearchResp[]> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post(`${this.apiUrl}/${searchParams.corpCode}`, JSON.stringify(searchParams), httpOptions).pipe(
      map((response: Object) => {
        const res = response['data'].documentInfo as DmsDocumentSearchResp[];
        return res;
      })
    );
  }
}
