import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MimeTypeDetectorService {
  constructor() {}

  detectMimeTypeFromArrayBuffer(arrayBuffer: ArrayBuffer): string | undefined {
    const uint8Array = new Uint8Array(arrayBuffer);
    const header = Array.from(uint8Array.slice(0, 4))
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');

    return this.getMimeTypeFromHeader(header);
  }
  getMimeTypeFromHeader(header: string): string | undefined {
    switch (header.toLowerCase()) {
      case '89504e47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case '25504446':
        return 'application/pdf';
      case '49492a00':
      case '4d4d002a':
        return 'image/tiff';
      case '504b0304':
      case '504b0506':
      case '504b0708':
        return 'application/zip';
      case '25504446':
        return 'application/pdf';
      case '3c3f786d6c':
      case '3c3f786d6d':
        return 'application/xml';
      case '3c68746d6c':
        return 'text/html';
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
      case 'ffd8ffe3':
      case 'ffd8ffe8':
        return 'image/jpeg';
      default:
        return 'text/plain';
    }
  }
}
