import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AgGridModule } from 'ag-grid-angular';
// import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { XpoBoardModule } from '@xpo-ltl/ngx-ltl-board';
// import { XpoAgGridComponent } from '@xpo-ltl/ngx-ltl-grid';
// import { XpoAgGridBoardModule } from '@xpo/ngx-core-board-ag-grid';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { SharedModule } from '../shared/shared.module';
import { SearchPageComponent } from './search-page.component';

import { XpoDateRangePickerModule } from '@xpo-ltl/ngx-ltl-core/date-range-picker';

import { MatSelectModule } from '@angular/material/select';

// TEST IMPORTS
// import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
// import { AgGridModule } from 'ag-grid-angular';

// import {
//   XpoCardModule,
//   XpoCommonModule,
//   XpoEmptyStateModule,
//   XpoShellModule,
//   XpoSnackBarModule,
// } from '@xpo-ltl/ngx-ltl-core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core/chips';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core/empty-state';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { ActionsRendererComponent } from './actions-renderer/actions-renderer.component';
import { CapitalizeDirective } from './capitalize.directive';
import { ColumnActionsHeader } from './column-actions-header/column-actions-header.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { SimpleAgGridDocumentSearchDataSourceService } from './simple-ag-grid-document-search-data-source';
import { TagRendererComponent } from './tag-renderer/tag-renderer.component';

// END TEST IMPORTS
@NgModule({
  declarations: [
    CapitalizeDirective,
    SearchPageComponent,
    SearchFormComponent,
    TagRendererComponent,
    ActionsRendererComponent,
    ColumnActionsHeader,
  ],
  exports: [SearchFormComponent],
  imports: [
    CommonModule,
    // MatDateRangePicker,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    // XpoBoardModule,
    XpoButtonModule,
    XpoDateRangePickerModule,
    XpoEmptyStateModule,
    XpoChipsModule,
    XpoIconModule,
    // XpoAgGridModule,
    XpoAgGridBoardModule,
  ],
  providers: [SimpleAgGridDocumentSearchDataSourceService],
})
export class SearchPageModule {}
