import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentsXrtService } from '../shared/services/documents-xrt.service';
import { DmsDocumentSearchResp } from '../shared/services/sdk-dms-2.0/lib/classes/dms-document-search-resp';

@Injectable()
export class SimpleAgGridDocumentSearchDataSourceService extends XpoBoardDataSource {
  defaultData = {
    items: [],
    resultCount: 0,
    pageSize: 0,
  };

  // (REMOVE COMMENT)
  // Replace MockXrtService with your api service that will retrieve data for the grid
  constructor(private apiService: DocumentsXrtService) {
    super();
  }

  // (REMOVE COMMENT)
  // This is the function that the board calls when it wants data, the state includes
  // the current state of the board which includes the criteria, current view, selected rows etc.
  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    // (REMOVE COMMENT)
    // Replace this call with whatever you need to call to get the data you want from your api service,
    return (
      this.apiService
        .search(DmsDocumentSearchResp, {
          filter: state.criteria,
          pageNumber: state.pageNumber,
          pageSize: this.pageSize,
          sortExpressions: state.sortOrder,
        })
        // (REMOVE COMMENT)
        // replace `data.items` with wherever your rows of the grid are in your response,
        // and replace `data.resultCount` with wherever you get the amount of rows that came back
        .pipe(
          map((data) => {
            return new XpoBoardData(state, data.items, data.resultCount, this.pageSize);
          }),
          catchError((error) => {
            // console.debug("Error", error);
            const board = new XpoBoardData(
              state,
              this.defaultData.items,
              this.defaultData.resultCount,
              this.defaultData.pageSize
            );
            return of(board);
          })
        )
    );
  }
}
