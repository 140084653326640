import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DocumentsService } from '../../shared/services/documents.service';

@Component({
  selector: 'app-tag-renderer',
  templateUrl: './tag-renderer.component.html',
  styleUrls: ['./tag-renderer.component.scss'],
})
export class TagRendererComponent implements ICellRendererAngularComp {
  params: any;
  constructor(@Inject(DocumentsService) private documentService: DocumentsService) {}

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  agInit(params: any): void {
    this.params = params;
  }
}
