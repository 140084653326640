import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlEnvironmentAndRoleGuard } from '@xpo-ltl/ngx-ltl';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.DATA_ENTRY_PAGE,
    loadChildren: () => import('./data-entry-page/data-entry-page.module').then((m) => m.DataEntryPageModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.SEARCH_PAGE,
    component: SearchPageComponent,
    canActivate: [XpoAuthenticationGuard, XpoLtlEnvironmentAndRoleGuard],
  },
  {
    path: AppRoutes.LANDING_PAGE,
    component: LandingPageComponent,
    canActivate: [XpoAuthenticationGuard, XpoLtlEnvironmentAndRoleGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: `/${AppRoutes.SEARCH_PAGE}`,
    //         redirectTo: `/`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
