<div>
  <form [formGroup]="filtersForm" (ngSubmit)="search()" class="search-form">
    <mat-form-field class="search-form__element mat-form-field-has-label search-dms-corp-code">
      <input matInput dmsCapitalize formControlName="corpCode" required class="search-form__input" />
      <mat-placeholder class="dms-input-placeholder">Corp Code</mat-placeholder>
    </mat-form-field>
    <mat-form-field class="search-form__element mat-form-field-has-label search-dms-doc-class">
      <input matInput dmsCapitalize formControlName="docClass" class="search-form__input" />
      <mat-placeholder class="dms-input-placeholder">Doc Class</mat-placeholder>
    </mat-form-field>
    <mat-form-field class="search-form__element mat-form-field-has-label search-dms-search-tags">
      <input
        matInput
        [matAutocomplete]="auto"
        type="text"
        aria-label="Search tags"
        class="search-form__input"
        formControlName="tagInput"
        [value]="filtersForm.value.searchTags"
        (change)="onChangeTagInput()"
      />
      <mat-placeholder class="dms-input-placeholder">Tags to be searched</mat-placeholder>
      <mat-autocomplete #auto="matAutocomplete" (opened)="onOpenSearchTagsSelector()">
        <mat-option [value]="[]">
          <mat-checkbox [checked]="filtersForm.value.searchTags.length == 0" (click)="clearSearchTags()">
            ALL
          </mat-checkbox>
        </mat-option>
        <mat-option *ngFor="let option of searchTagSelectOps" [value]="option.code">
          <mat-checkbox
            [checked]="filtersForm.value.searchTags.includes(option.code)"
            (change)="toggleSelection(option)"
            (click)="$event.stopPropagation()"
          >
            {{ option.title }}
          </mat-checkbox>
        </mat-option>
        <mat-option>
          <mat-checkbox
            [checked]="filtersForm.value.searchTags.toString().includes('PRO')"
            (change)="toggleSelection({ code: 'PRO', title: 'PRO' })"
            (click)="$event.stopPropagation()"
          >
            PRO
          </mat-checkbox>
        </mat-option>
        <mat-option
          *ngIf="!searchTagSelectOps.toString().includes(filtersForm.value.tagInput)"
          [value]="filtersForm.value.tagInput"
          (click)="onAddHandler()"
        >
          <xpo-icon iconName="add"></xpo-icon>
          Add {{ filtersForm.value.tagInput }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="search-form__element mat-form-field-has-label search-form__search-string">
      <input matInput formControlName="searchString" class="search-form__input" />
      <mat-placeholder class="dms-input-placeholder">Search String</mat-placeholder>
    </mat-form-field>
    <mat-form-field class="search-form__element mat-form-field-has-label search-form__search-date">
      <input matInput class="search-form__input" [matDatepicker]="picker" formControlName="minDateTime" />
      <mat-placeholder class="dms-input-placeholder">Archived from</mat-placeholder>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field xpoDatePicker class="search-form__element mat-form-field-has-label search-form__search-date">
      <input matInput class="search-form__input" [matDatepicker]="endPicker" formControlName="maxDateTime" />
      <mat-placeholder class="dms-input-placeholder">To</mat-placeholder>
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
    <div class="search-form__element mat-form-field-has-label search-form__actions">
      <xpo-button-group class="search-form__actions">
        <button
          xpoSmallButton
          mat-button
          mat-flat-button
          matTooltip="Perform search"
          aria-tooltip="Button that performs search given search criteria"
          class="search-form__action-button"
          type="submit"
          [disabled]="isLoading$ | async"
        >
          <mat-label class="search-form__action-button-label">Search</mat-label>
          <mat-icon>search</mat-icon>
        </button>
        <button
          mat-stroked-button
          xpoSmallButton
          matTooltip="Reset search criteria"
          aria-tooltip="Button that reset search criteria"
          (click)="clearForm($event)"
          class="serach-form__action-button"
        >
          <mat-label class="search-form__action-button-label">Reset criteria</mat-label>
          <mat-icon>filter_list_off</mat-icon>
        </button>
        <ng-container *ngIf="isLoading$ | async">
          <div class="search-form__spinner">
            <mat-progress-spinner mode="indeterminate" diameter="24" strokeWidth="4"> </mat-progress-spinner>
          </div>
        </ng-container>
      </xpo-button-group>
    </div>
  </form>
</div>
