import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DocumentsApiService } from './documents-api.service';
import { DmsDocumentSearchResp } from './sdk-dms-2.0/lib/classes/dms-document-search-resp';
import { DmsDocumentSearchRqst } from './sdk-dms-2.0/lib/classes/dms-document-search-rqst';
import { DmsRetrieveDocumentRqst } from './sdk-dms-2.0/lib/classes/dms-retrieve-document-rqst';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { MimeTypeDetectorService } from './file-type-detector.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  firstLoadSearchCriteria: DmsDocumentSearchRqst = ({} as unknown) as DmsDocumentSearchRqst;
  searchCriteriaSubject = new BehaviorSubject<DmsDocumentSearchRqst>(this.firstLoadSearchCriteria);
  seenTags = new Set<string>();
  displayNamesMap = new Map<string, string>();
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(
    private documentsApiService: DocumentsApiService,
    private http: HttpClient,
    private mimeTypeDetectorService: MimeTypeDetectorService
  ) {}

  clearSearchCriteria() {
    this.searchCriteriaSubject = new BehaviorSubject<DmsDocumentSearchRqst>(this.firstLoadSearchCriteria);
  }

  getDocuments(): Observable<DmsDocumentSearchResp[]> {
    const currentCriteria = this.searchCriteriaSubject.getValue();
    this.loadingSubject.next(true);
    return this.documentsApiService.getDocuments(currentCriteria).pipe(
      tap((res) => {
        this.loadingSubject.next(false);
        res.forEach((doc) => {
          doc.metadata.forEach((tag) => {
            this.seenTags.add(tag.tag);
            if (tag.tag === 'displayname') {
              this.displayNamesMap.set(doc.cdt.timestamp, tag.value);
            }
          });
        });
      }),
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        this.loadingSubject.next(false);
        return of([]);
      })
    );
  }

  getDocument(params: DmsRetrieveDocumentRqst): Observable<Blob> {
    const cacheKey = params.timestamp;
    const cachedBlobs = this.getSessionStorageCachedBlobs();

    if (cachedBlobs.hasOwnProperty(cacheKey)) {
      return this.retrieveCachedBlob(cachedBlobs[cacheKey]);
    }

    return this.documentsApiService.retrieveFile(params).pipe(
      mergeMap((res) =>
        from(this.createBlobFromBase64(res.body['data'].document.binary)).pipe(
          mergeMap((blob) => {
            const objectURL = window.URL.createObjectURL(blob);

            cachedBlobs[cacheKey] = objectURL;
            this.updateSessionStorageCachedBlobs(cachedBlobs);

            // Listen for the window unload event to revoke the URL
            window.addEventListener('unload', () => {
              URL.revokeObjectURL(cachedBlobs[cacheKey]);
              this.clearSessionStorageCachedBlobs();
            });

            return this.retrieveCachedBlob(cachedBlobs[cacheKey]);
          })
        )
      )
    );
  }

  private getSessionStorageCachedBlobs(): { [key: string]: string } {
    const cachedBlobsString = sessionStorage.getItem('cachedBlobs');
    return cachedBlobsString ? JSON.parse(cachedBlobsString) : {};
  }

  private updateSessionStorageCachedBlobs(cachedBlobs: { [key: string]: string }): void {
    sessionStorage.setItem('cachedBlobs', JSON.stringify(cachedBlobs));
  }

  private clearSessionStorageCachedBlobs(): void {
    sessionStorage.removeItem('cachedBlobs');
  }

  private createBlobFromBase64(base64: string): Promise<Blob> {
    return new Promise(async (resolve) => {
      const binaryString = window.atob(base64);
      const byteArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      const mimeType = await this.mimeTypeDetectorService.detectMimeTypeFromArrayBuffer(byteArray);

      resolve(new Blob([byteArray], { type: mimeType }));
    });
  }

  private retrieveCachedBlob(objectURL: string): Observable<Blob> {
    return this.http.get(objectURL, { observe: 'response', responseType: 'blob' }).pipe(map((res) => res.body as Blob));
  }

  loadDocument(params): Observable<Object> {
    return this.getDocument(params).pipe(
      map((res) => {
        const cachedBlobs = this.getSessionStorageCachedBlobs();
        const contentType = res.type;
        return {
          blobUrl: cachedBlobs[params.timestamp],
          contentType: contentType,
          blb: res,
        };
      })
    );
  }

  onDownload(params) {
    this.getDocument(params).subscribe(async (res) => {
      const displayName = this.displayNamesMap.get(params.timestamp);
      saveAs(res, `${params.timestamp}_${displayName}`);
    });
  }

  setSearchCriteria(params: DmsDocumentSearchRqst) {
    this.searchCriteriaSubject.next(params);
  }

  patchParams(patch) {
    const nextParams = this.searchCriteriaSubject.getValue();
    for (const key in patch) {
      if (nextParams.hasOwnProperty(key)) {
        if (nextParams[key] !== patch[key]) {
          nextParams[key] = patch[key];
        }
      }
    }
    this.searchCriteriaSubject.next(nextParams);
  }

  isSearchCriteriaDefined() {
    const searchCriteria = this.searchCriteriaSubject.getValue();
    // If the 'corpCode' property is undefined or doesn't exist, return false
    if (
      !searchCriteria.hasOwnProperty('corpCode') ||
      searchCriteria.corpCode === undefined ||
      searchCriteria.corpCode === null
    ) {
      return false;
    }
    // Otherwise, assume the search criteria is defined and return true
    return true;
  }

  getTagSelectOps(input: string): Observable<any[]> {
    const ops = Array.from(this.seenTags);
    const filteredOps = ops.filter((o) => o.includes(input));
    const multiSelectOps = filteredOps.map((str) => {
      return { title: str, code: str };
    });
    return of(Array.from(multiSelectOps));
  }
}
