import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DocumentsService } from '../../shared/services/documents.service';
import { DmsDocumentSearchRqst } from '../../shared/services/sdk-dms-2.0/lib/classes/dms-document-search-rqst';
import { SearchFormService } from '../../shared/services/search-form.service';
import { SimpleAgGridDocumentSearchDataSourceService } from '../simple-ag-grid-document-search-data-source';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  // providers: [SimpleAgGridDocumentSearchDataSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements OnInit {
  constructor(
    public dataSource: SimpleAgGridDocumentSearchDataSourceService,
    private documentService: DocumentsService,
    private searchFormService: SearchFormService
  ) { }

  isLoading$: Observable<boolean>;

  filtersForm: FormGroup;

  searchTagSelectOps: any[] = [];
  customAddedTagOps: any[] = [];

  searchDefaultParams = ({
    corpCode: undefined,
    docClass: '',
    // format: 'xml',
    searchTags: [],
    // the "from" date data is defaulted to one year before the current date.
    minDateTime: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    maxDateTime: new Date(),
  } as unknown) as DmsDocumentSearchRqst;

  initializeForm() {
    this.filtersForm = new FormGroup({
      // docId: new FormControl(''),
      corpCode: new FormControl(this.searchDefaultParams.corpCode, Validators.required),
      docClass: new FormControl(this.searchDefaultParams.docClass),
      minDateTime: new FormControl(this.searchDefaultParams.minDateTime),
      maxDateTime: new FormControl(this.searchDefaultParams.maxDateTime, Validators.required),
      searchString: new FormControl(this.searchDefaultParams.searchString),
      searchTags: new FormControl(this.searchDefaultParams.searchTags),
      tagInput: new FormControl(''),
    });
  }

  clearForm(event: MouseEvent) {
    event.stopPropagation();
    this.initializeForm();
    this.documentService.clearSearchCriteria();
    this.dataSource.refresh();
  }

  clearSearchTags() {
    this.filtersForm.patchValue({ searchTags: [] });
  }

  onAddHandler() {
    const tagInputValue = this.filtersForm.value.tagInput;
    if (tagInputValue) {
      this.customAddedTagOps.push({ code: this.filtersForm.value.tagInput, title: this.filtersForm.value.tagInput });
      this.searchTagSelectOps.push({
        code: this.filtersForm.value.tagInput,
        title: this.filtersForm.value.tagInput,
        checked: true,
      });
      this.filtersForm.patchValue({ tagInput: null });
    }
  }

  onChangeTagInput() {
    this.getSelectTagOptions(this.filtersForm.value.tagInput);
  }

  onOpenSearchTagsSelector() {
    this.getSelectTagOptions(this.filtersForm.value.tagInput);
  }

  ngOnInit(): void {
    this.isLoading$ = this.documentService.loading$;
    this.initializeForm();
    this.getSelectTagOptions();
    this.searchFormService.setFormComponent(this);
  }

  toggleSelection(optClicked: any) {
    const currentSearchTags = this.filtersForm.get('searchTags').value;
    const unselected = currentSearchTags.includes(optClicked.code);

    let updatedSearchTags;
    if (!unselected) {
      updatedSearchTags = [...currentSearchTags, optClicked.code];
    } else {
      updatedSearchTags = currentSearchTags.filter((t) => optClicked.code !== t);
    }
    this.filtersForm.patchValue({ searchTags: updatedSearchTags });
  }

  search() {
    if (this.filtersForm.valid) {
      const clone = { ...this.filtersForm.value };
      delete clone.tagInput;

      if (clone.maxDateTime) {
        const maxDateInclusive = new Date(
          this.filtersForm.value.maxDateTime.getFullYear(),
          clone.maxDateTime.getMonth(),
          clone.maxDateTime.getDate() + 1
        );
        clone.maxDateTime = maxDateInclusive;
      }

      this.documentService.setSearchCriteria(clone);
      this.dataSource.refresh();
    }
  }

  getSelectTagOptions(input = '') {
    return this.documentService.getTagSelectOps(input).subscribe((tags) => {
      this.searchTagSelectOps = tags.concat(this.customAddedTagOps);
    });
  }
}
